import React, { useState } from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";
import Img from "gatsby-image";
import { graphql } from "gatsby";
import * as Scroll from "react-scroll";
import { AnimationOnScroll } from "react-animation-on-scroll";
import Development from "../assets/Careers/careers-development.svg";
import Location from "../assets/Careers/careers-locations.svg";
import Modern from "../assets/Careers/careers-culture.svg";
import Salary from "../assets/Careers/careers-salary.svg";
import Holidays from "../assets/Careers/careers-holiday.svg";
import Healthcard from "../assets/Careers/careers-healthcare.svg";
import TrainingDev from "../assets/Careers/careers-training.svg";
import Pension from "../assets/Careers/careers-pensions.svg";
import CareersTable from "../components/Careers/careersTable";
import LeamingtonView from "../assets/Careers/leamington_spa_view.jpg";
import LutonView from "../assets/Careers/luton3x.jpg";
import VancouverView from "../assets/Careers/vancouver3x.jpg";
import SolvingProblems from "../assets/Careers/careers-solving-problems.svg";
import Seo from "../components/SEO";
import Started from "../components/Layout/started";
import Layout from "../components/layout";
import PageHeader from "../components/pageHeader";
import * as pageStyles from "./page.module.less";
import * as styles from "./taskcards.module.less";
import * as careerStyles from "./careers.module.less";

const { scroller } = Scroll;

const ModernCard = {
  img: Modern,
  title: "Our Culture",
  text: `We believe people perform at their best when they have the freedom to do their job without too many rules and barriers getting in the way. Our culture is based around empowerment and trust - we hire great people and know you will make the right decisions for the company and the customer.

    Our vision is to transform the aviation sector. Everyone can have a say and make a difference through our openness, how we work together and our one-team approach.`,
};

const LocationsCard = {
  img: Location,
  title: "Great Locations",
  text: `We have offices in various locations where the team meet regularly for the purposes of collaboration, team building, and planning.  We have a flexible approach to our working environment, supporting each person’s personal circumstances.`,
};

const DevelopmentCard = {
  img: Development,
  title: "Continuous Development",
  text: "We are creating a thriving culture where all our people grow with us and carve out their own path in the company. Creating products and services that transform the industry is no mean feat and we know this means our people need to keep up. That’s why we have individual training budgets, development time set aside - within work cycles and the ‘standard’ working week - plus access to all of our industry leading experts.",
};

const SalaryCard = {
  img: Salary,
  title: "Recognising Value",
  text: "At TrustFlight we’re keen for the best people to be part of our team, so we provide competitive salaries alongside great benefits including healthcare, flexible working, funding for learning, and many opportunities for career growth.",
};

const excellentHolidays = {
  img: Holidays,
  title: "Excellent holidays",
  text: "We offer a generous holiday allowance that increases the longer you are here. In addition,  take your birthday off on us if you would like to celebrate!",
};

const healthcare = {
  img: Healthcard,
  title: "Healthcare cover for all",
  text: "It is important to us that we all work in an environment that is supportive of health and wellbeing; healthcare cover for all our people protects your health, dental and ophthalmic requirements to support you physically and mentally.",
};

const training = {
  img: TrainingDev,
  title: "Training and development",
  text: "Our personal training allowance allows everyone to enhance their skills and knowledge.  The funds can be used towards attending a conference, joining a professional body, completing a course or purchasing online resources.",
};

const pension = {
  img: Pension,
  title: "Company pension",
  text: "Our generous company contribution to your pension is greater than the local requirements and over time you can plan effectively for your future with our matching contribution scheme.",
};

const CardWrapper = styled.div`
  width: 100%;
  height: 100%;
  text-align: center;
  align-items: center;
  padding: 40px 25px;
  border-radius: 4px;
  display: flex;
  img {
    width: 350px;
    margin-left: ${({ marginLeft }) => (marginLeft ? "20%" : "unset")};
  }
  @media screen and (max-width: 768px) {
    text-align: center;
    align-items: center;
  }
  @media screen and (max-width: 450px) {
    img {
      margin-left: unset;
      margin-bottom: 20px;
    }
  }
`;

const TextWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-left: ${({ leftMargin }) => (leftMargin ? "20%" : "unset")};
  @media screen and (max-width: 450px) {
    margin-left: unset;
  }
`;

const Text = styled.div`
  font-size: 16px;
  color: rgba(36, 45, 65, 0.7);
  letter-spacing: 0.02px;
  line-height: 1.88;
  font-weight: 400;
  text-align: left;
  padding: ${({ noPad }) => (noPad ? "0" : "20px 0")};
  width: 80%;
  @media screen and (max-width: 768px) {
    font-size: 14px;
  }
`;

const PerkCardWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
`;

const PerkImage = styled.img`
  width: 120px;
`;

const PerkCardTextWrapper = styled.div`
  width: 100%;
  margin-left: 20px;
`;

const PerkTitle = styled.div`
  font-weight: 600;
  color: #242d41;
  font-size: 16px;
  margin-bottom: 10px;
`;

const PerkText = styled.div`
  font-size: 14px;
  font-weight: 400;
  color: rgba(36, 45, 65, 0.7);
  line-height: 1.65;
  white-space: pre-line;
`;

const perksCard = (card) => {
  return (
    <PerkCardWrapper>
      <PerkImage src={card.img} />
      <PerkCardTextWrapper>
        <PerkTitle>{card.title}</PerkTitle>
        <PerkText>{card.text}</PerkText>
      </PerkCardTextWrapper>
    </PerkCardWrapper>
  );
};

const GradientSection = styled.div`
  background-image: linear-gradient(to top, #f0f5f9, #ffffff);
  width: 100vw;
  padding-bottom: 60px;
`;

const SiteDiv = styled.div`
  height: 150px;
  width: 100%;
  padding: 12px 10px;
  background-image: url(${(props) => props.image});
  background-size: cover;
  color: white;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
  position: relative;
  cursor: pointer;
  transition: all 0.5s;
  &:hover {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  }
  h4 {
    font-size: 18px;
    font-weight: 600;
    color: white;
    margin-bottom: 4px;
    position: relative;
    z-index: 9;
  }
  h5 {
    font-size: 16px;
    color: white;
    margin-bottom: 0;
    z-index: 9;
    position: relative;
    z-index: 9;
  }
  @media screen and (max-width: 1200px) {
    height: 120px;
    h5 {
      font-size: 16px;
    }
  }
  @media screen and (max-width: 991px) {
    margin-bottom: 30px;
  }
  @media screen and (max-width: 768px) {
    height: 150px;
    margin-bottom: 20px;
  }
  @media screen and (max-width: 576px) {
    height: 120px;
    margin-bottom: 15px;
  }
`;

const LeamingtonDiv = styled.div`
  ${(props) =>
    props.display === "leamington" ? "display: block" : "display: none"};
`;

const LeamingtonImage = styled.div`
  height: 100%;
  ${(props) =>
    props.display === "leamington" ? "display: block" : "display: none"};
`;

const LutonDiv = styled.div`
  ${(props) =>
    props.display === "luton" ? "display: block" : "display: none"};
`;

const LutonImage = styled.div`
  height: 100%;
  ${(props) =>
    props.display === "luton" ? "display: block" : "display: none"};
`;

const VancouverDiv = styled.div`
  ${(props) =>
    props.display === "vancouver" ? "display: block" : "display: none"};
`;

const VancouverImage = styled.div`
  height: 100%;
  ${(props) =>
    props.display === "vancouver" ? "display: block" : "display: none"};
`;

const ExpectTitleWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 20px 0 0 0;
  @media screen and (max-width: 768px) {
    padding-bottom: 10px;
    padding-top: 20px;
  }
`;

const ExpectTitle = styled.h1`
  text-align: center;
  font-size: 30px;
  font-weight: 600;
  letter-spacing: 0.04px;
  text-align: center;
  color: #242d41;
  @media screen and (max-width: 1200px) {
    font-size: 26px;
  }
  @media screen and (max-width: 991px) {
    font-size: 24px;
  }
  @media screen and (max-width: 768px) {
    font-size: 22px;
  }
  @media screen and (max-width: 576px) {
    font-size: 20px;
  }
`;

const LocationTitle = styled.h1`
  font-size: 30px;
  font-weight: 600;
  margin-bottom: 8px;
  @media screen and (max-width: 1200px) {
    font-size: 26px;
  }
  @media screen and (max-width: 991px) {
    font-size: 24px;
  }
  @media screen and (max-width: 768px) {
    font-size: 22px;
  }
  @media screen and (max-width: 576px) {
    font-size: 20px;
  }
`;

const LocationSubTitle = styled.h3`
  font-size: 24px;
  padding-bottom: 20px;
  @media screen and (max-width: 991px) {
    font-size: 22px;
  }
  @media screen and (max-width: 768px) {
    font-size: 20px;
  }
  @media screen and (max-width: 576px) {
    font-size: 18px;
  }
`;

const LocationText = styled.p`
  font-size: 16px;
  line-height: 1.65;
`;

const OpportunitySelectionDiv = styled.div`
  display: flex;
  justify-content: start;
  flex-wrap: wrap;
  margin-top: 15px;
  border-bottom: 1px #ededed solid;
  margin-bottom: 15px;
`;

const LocationSelection = styled.div`
  padding: 15px 25px;
  font-size: 16px;
  ${(props) => (props.selected ? "color: #1E5BB5" : "color: #5c6f78")};
  ${(props) =>
    props.selected
      ? "border-bottom: 2px solid #1E5BB5"
      : "border-bottom: 1px solid transparent"};
  z-index: 5;
  cursor: pointer;
  &:hover {
    ${(props) => (props.selected ? "color: #1E5BB5" : "color: black")};
  }
  @media screen and (max-width: 768px) {
    width: 100%;
    text-align: center;
    border-bottom: 1px solid #ededed;
    ${(props) => props.selected && "border-bottom: 2px solid #1E5BB5"};
  }
`;

const SiteOverlay = styled.div`
  background: linear-gradient(to bottom, #062955, #062955);
  opacity: 0.65;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 0;
  transition: all 0.2s;
  ${(props) =>
    props.active &&
    "background: linear-gradient(to bottom, rgba(85, 85, 85, 0), #000);"}
`;

const CenteredDiv = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 20px;
`;

const Careers = (props) => {
  const [siteDisplay, setSiteDisplay] = useState("leamington");
  const [placeSelection, setPlaceSelection] = useState("all");

  const { quoteImg, leamingtonOffice, lutonOffice, vancouverOffice } =
    props.data;

  return (
    <Layout>
      <Seo
        title="If you aspire to make a difference in the world of aviation you are in the right place"
        description="We are creating aviation for the world, helping our customers excel in safety and efficiency. By transforming the way our customers work, we are enabling them to focus on insights and data that really matter. We have an exciting horizon where the skies really are the limit!"
      />
      <PageHeader
        header="If you aspire to make a difference in the world of aviation you are in the right place"
        subHeader="We are creating aviation for the world, helping our customers excel in safety and efficiency. By transforming the way our customers work, we are enabling them to focus on insights and data that really matter. We have an exciting horizon where the skies really are the limit!"
        link="/careers/#positions"
        linkText="Join the team"
      />
      <Container>
        <Row
          className={`justify-content-md-center ${pageStyles.pageParentRow}`}
        >
          <Col lg={11} md={12}>
            <Row>
              <Col
                md={7}
                className={`${styles.colImageParent} ${pageStyles.coliPad}`}
              >
                <AnimationOnScroll
                  animateOnce
                  animateIn="animate__fadeIn"
                  offset={0}
                >
                  <Img
                    fluid={quoteImg.childImageSharp.fluid}
                    className={careerStyles.quoteImage}
                  />
                </AnimationOnScroll>
              </Col>
              <Col
                lg={4}
                md={5}
                className={`${styles.flexDescription} ${pageStyles.coliPad}`}
              >
                <AnimationOnScroll
                  animateOnce
                  animateIn="animate__fadeIn"
                  offset={0}
                  delay={200}
                >
                  <p
                    className={`${styles.description} ${styles.quote} ${styles.quoteNoMargin}`}
                  >
                    “The aviation sector has a lot of legacy processes and
                    software that values function over usability and design.
                    Since joining TrustFlight, I've realised that this isn't how
                    it has to be - the team embraces new technologies, works
                    dynamically, and considers the design and usability of
                    software right from the beginning. Working in a growing team
                    means there's always new challenges and ideas to discuss,
                    but being able to make good software and see it being used
                    by customers in a matter of days or weeks is incredible.”
                  </p>
                  <p className={`${styles.description} ${styles.name}`}>
                    Software Developer
                  </p>
                </AnimationOnScroll>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      <GradientSection>
        <Row
          className={`justify-content-md-center ${pageStyles.pageParentRow}`}
        >
          <Col md={12}>
            <CenteredDiv>
              <AnimationOnScroll
                animateOnce
                animateIn="animate__fadeInDown"
                offset={0}
              >
                <h3 className={styles.title}>Why join us?</h3>
              </AnimationOnScroll>
            </CenteredDiv>
          </Col>
        </Row>
        <Row>
          <Col>
            <AnimationOnScroll
              animateOnce
              animateIn="animate__fadeInLeft"
              offset={0}
            >
              <CardWrapper marginLeft>
                <Row>
                  <Col sm={12} md={5}>
                    <img src={SolvingProblems} alt="icon" />
                  </Col>
                  <Col sm={12} md={7}>
                    <TextWrapper>
                      <Text>
                        {`Just a few short years ago our founders, commercial pilots themselves, realised there was a need to improve efficiency and minimise the risk to safety for all air travel customers. Since then, digital transformation has become the number one item on almost every airline’s agenda.`}
                      </Text>
                      <Text noPad>
                        {`We have created innovative solutions that go beyond digitising the current process by re-engineering how data is collected, accessed and stored. Our continually growing team of experts with a diverse set of skills develop services for our enviable list of customers.`}
                      </Text>
                      <Text>
                        {`We work with forward thinking operators and regulators in the aviation industry providing them with an exceptional suite of products to increase their efficiency, improve safety and maintain compliance for all who benefit from aviation.`}
                      </Text>
                    </TextWrapper>
                  </Col>
                </Row>
              </CardWrapper>
            </AnimationOnScroll>
          </Col>
        </Row>
      </GradientSection>
      <Container>
        <Row
          className={`justify-content-md-center ${pageStyles.pageParentRow}`}
        >
          <Col xl={11} lg={12}>
            <Row>
              <ExpectTitleWrapper>
                <AnimationOnScroll
                  animateOnce
                  animateIn="animate__fadeInDown"
                  offset={0}
                >
                  <ExpectTitle>What is it like working here?</ExpectTitle>
                </AnimationOnScroll>
              </ExpectTitleWrapper>
            </Row>
            <Row
              className={`justify-content-md-center ${pageStyles.pageParentRowMedium}`}
            >
              <Col
                md={6}
                className={`${pageStyles.coliPadHalf} ${careerStyles.marginBottom}`}
              >
                <AnimationOnScroll
                  animateOnce
                  animateIn="animate__fadeInLeft"
                  offset={0}
                >
                  {perksCard(ModernCard)}
                </AnimationOnScroll>
              </Col>
              <Col
                md={6}
                className={`${pageStyles.coliPadHalf} ${careerStyles.marginBottom}`}
              >
                <AnimationOnScroll
                  animateOnce
                  animateIn="animate__fadeInRight"
                  offset={0}
                >
                  {perksCard(DevelopmentCard)}
                </AnimationOnScroll>
              </Col>
              <Col
                md={6}
                className={`${pageStyles.coliPadHalf} ${careerStyles.marginBottom}`}
              >
                <AnimationOnScroll
                  animateOnce
                  animateIn="animate__fadeInLeft"
                  offset={0}
                >
                  {perksCard(LocationsCard)}
                </AnimationOnScroll>
              </Col>
              <Col
                md={6}
                className={`${pageStyles.coliPadHalf} ${careerStyles.marginBottom}`}
              >
                <AnimationOnScroll
                  animateOnce
                  animateIn="animate__fadeInRight"
                  offset={0}
                >
                  {perksCard(SalaryCard)}
                </AnimationOnScroll>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      <GradientSection>
        <Container>
          <Row
            className={`justify-content-md-center ${pageStyles.pageParentRow}`}
          >
            <Col xl={11} lg={12}>
              <ExpectTitleWrapper>
                <AnimationOnScroll
                  animateOnce
                  animateIn="animate__fadeInDown"
                  offset={0}
                >
                  <ExpectTitle>We recognise our talent</ExpectTitle>
                </AnimationOnScroll>
              </ExpectTitleWrapper>
              <CenteredDiv>
                <AnimationOnScroll
                  animateOnce
                  animateIn="animate__fadeInUp"
                  offset={0}
                >
                  <h5
                    className={`${styles.subTitle} ${careerStyles.rewardsTitle}`}
                  >
                    Our benefits reflect how valuable we think everyone who
                    joins us is to the success of TrustFlight. Wherever you are
                    located and whatever you do, everyone has access to all of
                    these great ways to provide you with the environment and
                    resources that will help drive innovation, while staying
                    happy, healthy and excited about what we are trying to
                    achieve.
                  </h5>
                </AnimationOnScroll>
              </CenteredDiv>
            </Col>
            <Row
              className={`justify-content-md-center ${pageStyles.pageParentRowMedium} ${careerStyles.rowMargin}`}
            >
              <Col
                md={6}
                className={`${pageStyles.coliPadHalf} ${careerStyles.marginBottom}`}
              >
                <AnimationOnScroll
                  animateOnce
                  animateIn="animate__fadeInLeft"
                  offset={0}
                >
                  {perksCard(excellentHolidays)}
                </AnimationOnScroll>
              </Col>
              <Col
                md={6}
                className={`${pageStyles.coliPadHalf} ${careerStyles.marginBottom}`}
              >
                <AnimationOnScroll
                  animateOnce
                  animateIn="animate__fadeInRight"
                  offset={0}
                >
                  {perksCard(healthcare)}
                </AnimationOnScroll>
              </Col>
              <Col
                md={6}
                className={`${pageStyles.coliPadHalf} ${careerStyles.marginBottom}`}
              >
                <AnimationOnScroll
                  animateOnce
                  animateIn="animate__fadeInLeft"
                  offset={0}
                >
                  {perksCard(training)}
                </AnimationOnScroll>
              </Col>
              {/* <Col
                md={6}
                className={`${pageStyles.coliPadHalf} ${careerStyles.marginBottom}`}
              >
                <AnimationOnScroll animateOnce animateIn="animate__fadeInRight" offset={0}>
                  {perksCard(travel)}
                </AnimationOnScroll>
              </Col> */}
              <Col
                md={6}
                className={`${pageStyles.coliPadHalf} ${careerStyles.marginBottom}`}
              >
                <AnimationOnScroll
                  animateOnce
                  animateIn="animate__fadeInRight"
                  offset={0}
                >
                  {perksCard(pension)}
                </AnimationOnScroll>
              </Col>
              {/* <Col
                md={6}
                className={`${pageStyles.coliPadHalf} ${careerStyles.marginBottom}`}
              >
                <AnimationOnScroll animateOnce animateIn="animate__fadeInRight" offset={0}>
                  {perksCard(pilotLicence)}
                </AnimationOnScroll>
              </Col> */}
            </Row>
          </Row>
        </Container>
      </GradientSection>
      <Container>
        <Row
          className={`justify-content-md-center ${pageStyles.pageParentRow}`}
        >
          <Col xl={11} lg={12}>
            <ExpectTitleWrapper>
              <AnimationOnScroll
                animateOnce
                animateIn="animate__fadeInDown"
                offset={0}
              >
                <ExpectTitle className={careerStyles.marginBottom}>
                  Take a look inside some of our offices
                </ExpectTitle>
              </AnimationOnScroll>
            </ExpectTitleWrapper>
            <Row>
              <Col md={6} lg>
                <AnimationOnScroll
                  animateOnce
                  animateIn="animate__fadeInUp"
                  offset={0}
                >
                  <SiteDiv
                    image={LeamingtonView}
                    onClick={() => {
                      setSiteDisplay("leamington");
                      window &&
                        window.innerWidth < 768 &&
                        scroller.scrollTo("siteLocation", {
                          duration: 500,
                          smooth: true,
                          offset: -60,
                        });
                    }}
                  >
                    <SiteOverlay active={siteDisplay === "leamington"} />
                    <h4>Leamington Spa</h4>
                    <h5>Software Development</h5>
                  </SiteDiv>
                </AnimationOnScroll>
              </Col>
              <Col md={6} lg>
                <AnimationOnScroll
                  animateOnce
                  animateIn="animate__fadeInUp"
                  offset={0}
                  delay={200}
                >
                  <SiteDiv
                    image={LutonView}
                    onClick={() => {
                      setSiteDisplay("luton");
                      window &&
                        window.innerWidth < 768 &&
                        scroller.scrollTo("siteLocation", {
                          duration: 500,
                          smooth: true,
                          offset: -60,
                        });
                    }}
                  >
                    <SiteOverlay active={siteDisplay === "luton"} />
                    <h4>Luton</h4>
                    <h5>Aircraft Technical Services</h5>
                  </SiteDiv>
                </AnimationOnScroll>
              </Col>
              <Col md={6} lg>
                <AnimationOnScroll
                  animateOnce
                  animateIn="animate__fadeInUp"
                  offset={0}
                  delay={300}
                >
                  <SiteDiv
                    image={VancouverView}
                    onClick={() => {
                      setSiteDisplay("vancouver");
                      window &&
                        window.innerWidth < 768 &&
                        scroller.scrollTo("siteLocation", {
                          duration: 500,
                          smooth: true,
                          offset: -60,
                        });
                    }}
                  >
                    <SiteOverlay active={siteDisplay === "vancouver"} />
                    <h4>Vancouver</h4>
                    <h5>Software Development</h5>
                  </SiteDiv>
                </AnimationOnScroll>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="justify-content-md-center" name="siteLocation">
          <Col xl={11} lg={12} className={careerStyles.locationsCol}>
            <Row>
              <Col md={7}>
                <AnimationOnScroll
                  animateOnce
                  animateIn="animate__fadeIn"
                  offset={0}
                >
                  <VancouverDiv display={siteDisplay}>
                    <LocationTitle>Vancouver</LocationTitle>
                    <LocationSubTitle>
                      Software Development & Aircraft Technical Services
                    </LocationSubTitle>
                    <LocationText>
                      TrustFlight’s Vancouver office is the newest and fastest
                      growing office and represents our first international
                      expansion. Situated in the beautiful downtown area of the
                      city, we are well connected to the thriving tech scene in
                      the Pacific Northwest. Vancouver is home to a team of
                      highly experienced software and airworthiness experts who
                      cover the full spectrum of aircraft technical services,
                      and is at the forefront of our efforts to build out an
                      innovative aviation data platform and ecosystem. Vancouver
                      has a huge amount to offer, frequently ranked as one of
                      the best cities in the world to live in, with no shortage
                      of activities and entertainment, as well as great local
                      transport links - we're thrilled to make it our home in
                      Canada!
                    </LocationText>
                  </VancouverDiv>
                  <LeamingtonDiv display={siteDisplay}>
                    <LocationTitle>Leamington Spa</LocationTitle>
                    <LocationSubTitle>Software Development</LocationSubTitle>
                    <LocationText>
                      Our Leamington office focuses primarily on software
                      development as well as providing the focal point for our
                      products and services. Converted from an upmarket
                      restaurant, the office has plenty of character and has
                      been completely refurbished to provide an incredible
                      working environment. Leamington Spa is a beautiful town in
                      the middle of England with great transport links to
                      Birmingham, London and even further afield. Our office is
                      right in the centre of town with plenty of cafes,
                      restaurants and parks surrounding us. We're less than 30
                      minutes from Birmingham International, and easy walking
                      distance to the train station and bus stops.
                    </LocationText>
                  </LeamingtonDiv>
                  <LutonDiv display={siteDisplay}>
                    <LocationTitle>Luton</LocationTitle>
                    <LocationSubTitle>
                      Aircraft Technical Services
                    </LocationSubTitle>
                    <LocationText>
                      Luton is home to our approved Continuing Airworthiness and
                      Technical Services team. Within easy reach of Luton
                      Airport and Central London, we have a modern, spacious
                      office where our highly experienced team uses the latest
                      technologies to support aircraft around the world with a
                      range of services including planning, reliability
                      monitoring and technical assistance. This is also our base
                      for conducting aircraft audit services and lease
                      transition activities.
                    </LocationText>
                  </LutonDiv>
                </AnimationOnScroll>
              </Col>
              <Col md={5} className={styles.colImageParent}>
                <AnimationOnScroll
                  animateOnce
                  animateIn="animate__fadeIn"
                  offset={0}
                  delay={200}
                >
                  <VancouverImage display={siteDisplay}>
                    <Img
                        fluid={vancouverOffice.childImageSharp.fluid}
                        className={careerStyles.officeImage}
                    />
                  </VancouverImage>
                  <LeamingtonImage display={siteDisplay}>
                    <Img
                      fluid={leamingtonOffice.childImageSharp.fluid}
                      className={careerStyles.officeImage}
                    />
                  </LeamingtonImage>
                  <LutonImage display={siteDisplay}>
                    <Img
                      fluid={lutonOffice.childImageSharp.fluid}
                      className={careerStyles.officeImage}
                    />
                  </LutonImage>
                </AnimationOnScroll>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row
          className={`justify-content-md-center ${pageStyles.pageParentRow}`}
        >
          <Col md={12}>
            <Row>
              <Col md={12}>
                <CenteredDiv>
                  <AnimationOnScroll
                    animateOnce
                    animateIn="animate__fadeInDown"
                    offset={0}
                  >
                    <h3 className={styles.title}>Ready to come on board?</h3>
                  </AnimationOnScroll>
                </CenteredDiv>
              </Col>
              <Col lg={{ span: 10, offset: 1 }} md={{ span: 12, offset: 0 }}>
                <CenteredDiv>
                  <AnimationOnScroll
                    animateOnce
                    animateIn="animate__fadeInUp"
                    offset={0}
                  >
                    <h5 className={styles.subTitle}>
                      We're always on the lookout for exceptional people to join
                      our team. The aviation industry is one of the most highly
                      regulated, complex, and cautious industries in the world,
                      but we don't let that stop us from creating incredible
                      products. If you think you have something to offer us,
                      please take a look at our open roles or get in touch
                      directly.
                    </h5>
                  </AnimationOnScroll>
                </CenteredDiv>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row
          className={`justify-content-md-center ${pageStyles.pageParentRowMedium}`}
          name="positions"
        >
          <Col lg={10} md={12}>
            <AnimationOnScroll
              animateOnce
              animateIn="animate__fadeInUp"
              offset={0}
            >
              <OpportunitySelectionDiv>
                <LocationSelection
                  selected={placeSelection === "all"}
                  onClick={() => setPlaceSelection("all")}
                >
                  ALL LOCATIONS
                </LocationSelection>
                <LocationSelection
                    selected={placeSelection === "Vancouver"}
                    onClick={() => setPlaceSelection("Vancouver")}
                >
                  VANCOUVER
                </LocationSelection>
                <LocationSelection
                  selected={placeSelection === "Leamington Spa"}
                  onClick={() => setPlaceSelection("Leamington Spa")}
                >
                  LEAMINGTON SPA
                </LocationSelection>
                <LocationSelection
                  selected={placeSelection === "Luton"}
                  onClick={() => setPlaceSelection("Luton")}
                >
                  LUTON
                </LocationSelection>
              </OpportunitySelectionDiv>
            </AnimationOnScroll>
            <div>
              <AnimationOnScroll
                animateOnce
                animateIn="animate__fadeIn"
                offset={0}
              >
                <CareersTable selection={placeSelection} />
              </AnimationOnScroll>
            </div>
            <div className={careerStyles.started}>
              <Started
                title="Don't see the perfect role?"
                text="Our search for outstanding talent at TrustFlight is never over, so don’t worry if your perfect role isn’t listed above. We’d still love to hear from you."
                buttonText="Join the team."
                buttonTextStrong="Email us with your CV"
                link="mailto:careers@trustflight.com"
              />
            </div>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
};
export default Careers;

export const query = graphql`
  query CareersQuery {
    quoteImg: file(relativePath: { eq: "placeholder_img.JPG" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    leamingtonOffice: file(relativePath: { eq: "Careers/leamington1x.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    lutonOffice: file(relativePath: { eq: "Careers/luton_office.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    vancouverOffice: file(relativePath: { eq: "Careers/vancouver1x.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
